import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hello world!",
  "slug": "hello-world",
  "author": "Cameron Billings",
  "date": "2021-01-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`My first blog header!!!!!!!!!!!!!!`}</h3>
    <p>{`This is my first blog post! I wrote it with MDX!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      